import React, { Fragment } from "react";
import * as Constant from "../../constants";
import { connect } from "react-redux";
import {  Link,withRouter } from "react-router-dom";
import autoBind from 'react-autobind';
import cookie from "react-cookies";
import "./registration.css";
import { registerUser } from "../../actions/login";
import backgroundForRegister from '../../static/register_background.png'
import ubuilderBlue from '../../static/U-Builder_Logo_Blue_Color.png'
import uniracWhite from '../../static/Unirac_White.png'
import { SOCIAL_MEDIA_LINKS } from '../utils/constants.';
import eyeOpened from '../../static/Icon material-remove-red-eye.svg'
import eyeClosed from '../../static/Icon awesome-eye-slash.svg'
class Registration extends React.Component {

    constructor(props) {
        super(props);
        autoBind(this);

        let data = cookie.load(Constant.USER_DATA);
        if (data) {
            this.props.history.push("/");
        }
        this.state = { email: '', password: '', password_repeat: '', error: { email: '', password: '', password_repeat: '' }, buttonDisable: false, visiblePassword: false, visibleConfirmPassword: false };
        
        const appElement = document.querySelector('.App');
        if(appElement){
            appElement.style.background = `url(${backgroundForRegister})`;
            appElement.style.backgroundRepeat =  'no-repeat';
            appElement.style.height = 'calc(100vh)';
            appElement.style.position = 'relative';
            //appElement.style.backgroundSize = '110%';
            appElement.style.backgroundPosition = '44% 77%';
            appElement.style.backgroundOrigin = 'padding-box';
            appElement.style.margin = '0';
        }
    }
    /**
     * Handling input change events.
     * @param  {Object} event 
     */
    handleInputChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value
        });
        this.validateInputFields(name,value);
    }

    viewPassword() {
        this.setState({
            visiblePassword: !this.state.visiblePassword,
        })
    }

    viewConfirmPassword() {
        this.setState({
            visibleConfirmPassword: !this.state.visibleConfirmPassword,
        })
    }

    validateInputFields = (fieldName, value) => {
        let disable = false, errorMessage=null;
        if(fieldName==="email"){
            if(!value.includes("@")){
                disable = true;
                errorMessage = "Please enter valid email address";  
            }
            this.setState({
                error: {
                    ...this.state.error,
                    [fieldName]: errorMessage,
                },
            })
        }
        if(fieldName==="password"){
            if(value.length < 8){
                disable = true;
                errorMessage = "The password field must be contain atleast 8 characters";  
            }
            this.setState({
                error: {
                    ...this.state.error,
                    [fieldName]: errorMessage,
                },
            })
        }
        if(fieldName === "password_repeat"){
            if(value !== this.state.password){
                disable = true;
                errorMessage = "Passwords doesn't match";
            }
            this.setState({
                error: {
                    ...this.state.error,
                    [fieldName]: errorMessage,
                },
            })
        }
        this.setState({
            buttonDisable: disable
        })

    }
    /**
       * Form submit function with validation check.
       * @param  {Object} event 
       */
    formSubmit = (event) => {
        event.preventDefault();
        this.props.registerUser(this.state, this.props.history);

    }

    render() {
        const inputTypeForPassword = this.state.visiblePassword ? "text" : "password";
        const EyeIconForPassword = this.state.visiblePassword ? eyeOpened : eyeClosed; 
        const inputTypeForConfirmPassword = this.state.visibleConfirmPassword ? "text" : "password";
        const EyeIconForConfirmPassword = this.state.visibleConfirmPassword ? eyeOpened : eyeClosed; 

        return (
            <Fragment>
                <div className="container">
                    <div>
                        <div className="RegisterBox">
                            <form onSubmit={this.formSubmit} style={{ marginTop: '-10px' }} className="justify-content-center">
                                <div className="text-center registration-title">
                                    <img src={ubuilderBlue} className="ubuilder-blue-logo" alt="ubuider-blue-logo" />
                                </div>
                                <div className="form-group email">
                                    <input type="email" className={`form-control ${this.state.error.email ? "invalid" : ""}`} value={this.state.email}
                                        onChange={this.handleInputChange} name="email" required placeholder="Enter Your Email ID" />
                                    <span className="error-message">{this.state.error.email}</span>

                                </div>
                                <div className="form-group password registration">
                                    <input type={inputTypeForPassword} className={`form-control ${this.state.error.password ? "invalid" : ""}`} value={this.state.password}
                                        onChange={this.handleInputChange} name="password" required placeholder="Enter Your Password" />
                                    <span className="view-password" onClick={this.viewPassword}><img src={EyeIconForPassword} alt="eye-icon"/></span>
                                    <span className="error-message">{this.state.error.password}</span>
                                </div>
                                <div className="form-group confirm-password">
                                    <input type={inputTypeForConfirmPassword} className={`form-control ${this.state.error.password_repeat ? "invalid" : ""}`} value={this.state.password_repeat}
                                        onChange={this.handleInputChange} name="password_repeat" required placeholder="Confirm Your Password" />
                                    <span className="view-password" onClick={this.viewConfirmPassword}><img src={EyeIconForConfirmPassword} alt="eye-icon"/></span>
                                    <span className="error-message">{this.state.error.password_repeat}</span>
                                </div>
                                <button type="submit" className="btn btn-block submitButtonColor" disabled={this.state.buttonDisable}>
                                    Let's Create 
                                    <img src={require('../../static/forward_arrow_icon.svg')} className="forward-icon" alt="forward-icon"/>
                                </button>
                            </form>
                            <hr/>
                            <div className="text-center loginRegNavLink">
                                Already have an account ?
                                <Link to={'/login'} className="login-link"> Login</Link>
                            </div>
                            <div className="text-center social-media-links">
                                <a href={SOCIAL_MEDIA_LINKS.facebook}><img src={require('../../static/Icon awesome-facebook.svg')} alt="facebook-icon"/></a>
                                <a href={SOCIAL_MEDIA_LINKS.twitter}><img src={require('../../static/Icon awesome-twitter.svg')} alt="twitter-icon"/></a>
                                <a href={SOCIAL_MEDIA_LINKS.linkedin}><img src={require('../../static/Icon awesome-linkedin.svg')} alt="linkedin-icon"/></a>
                                <a href={SOCIAL_MEDIA_LINKS.instagram}><img src={require('../../static/Icon awesome-instagram.svg')} alt="instagram-icon"/></a>
                                <a href={SOCIAL_MEDIA_LINKS.instagram}><img src={require('../../static/Icon awesome-youtube.svg')} alt="youtube-icon"/></a>
                            </div>
                        </div>
                    </div>
                    <img src={uniracWhite} className="unirac-white-logo" alt="unirac-white-logo"/>
                </div>
            </Fragment>
        )
    }
}


export default connect(null, { registerUser })(withRouter(Registration))
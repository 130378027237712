import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link,withRouter } from "react-router-dom";
import autoBind from 'react-autobind';
import sendSymbol from "../../static/sendSymbol.svg"
import flower from "../../static/flower.svg"
import arrowRight from "../../static/arrow-right.svg"
import "./verifyEmail.css";
import { resendEmailVerification, countForResendEmailAction } from "../../actions/login";

class VerifyEmail extends React.Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state={
            count: JSON.parse(window.sessionStorage.getItem('countForResendEmail')) || this.props.countForResendEmail || 60,
            isDisabled: true
        }
    }
    componentDidMount() {
        if(this.props.location.state.message){
            this.props.resendEmailVerification({'email':this.props.location.state.email});
        }
        this.startCountdown();
      }
    
    startCountdown = () => {
    this.setState({ isDisabled: true });

    const countdownInterval = setInterval(() => {
        this.setState(prevState => ({
        count: prevState.count - 1
        }), () => {
        window.sessionStorage.setItem('countForResendEmail', this.state.count);
        this.props.countForResendEmailAction(this.state.count)
        if (this.state.count === 0) {
            this.setState(prevState => ({
                count: 60,
                isDisabled: !prevState.isDisabled
            }))
            clearInterval(countdownInterval);
        }
        });
    }, 1000);
    };
    handleResendEmail = (event) => {
        event.preventDefault();
        this.setState({
            count: 60,
            isDisabled: true
        })
        this.startCountdown();
        this.props.resendEmailVerification({'email':this.props.location.state.email});
    }
    
    render() {
        return (
            <Fragment>
                <div className="container verify-email-body">
                    <div className="row justify-content-center align-items-center height80Vh">
                        <div className="verify-email">
                            <div className="text-center">
                                <img src={sendSymbol} className="send-icon" alt="send"/>
                                <br/><br/><br/>
                                <h2>Verify your email address</h2>
                                <p>We have sent a verification link to <span className="email-name">{this.props.location.state.email}</span></p>
                                <br/>
                                <h4>Click on the link to complete the verification process</h4>
                                <span>You might need to <strong>check your spam folder as well</strong></span>
                                <br/><br/>
                                {this.state.isDisabled?<p>If you are unable to receive mail, click on the resend email in {this.state.count}s</p>:null}
                                <div className="bottom">
                                    <button type="submit" className="btn resendEmailButtonColor" onClick={this.handleResendEmail} disabled={this.state.isDisabled}>
                                        Resend email
                                    </button>
                                    <Link to={'/login'}>Return to site <img src={arrowRight} className="right-arrow" alt="right-arrow"/></Link>
                                </div>
                            </div>
                        </div>
                        <img src={flower} className="flower-icon" alt="flower"/>
                    </div>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state, props) {
    return {
    countForResendEmail: state.loginInfo.countForResendEmail
    }
}

export default connect(mapStateToProps,{ resendEmailVerification, countForResendEmailAction })(withRouter(VerifyEmail))
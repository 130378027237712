import * as Constants from '../constants'
import cookies from 'react-cookies'
import { API_LOGIN, API_REGISTER, API_RESEND_VERIFY_EMAIL } from "../urls";
import config from "../local_config"
import * as moment from 'moment'

export function loginPost(data,router) {
    return Constants.apiAction({
        url: API_LOGIN,
        method: Constants.REQUEST_POST,
        data: data,
        onSuccess: loginSuccess,
        onFailure: loginFailure,
        label: Constants.LOGIN_REQUESTED,
        router: router
    })
}

function loginSuccess(data) {
    cookies.save(Constants.USER_DATA, JSON.stringify(data), { domain: config.domainName, expires: moment().add(1,'year').toDate() });
    cookies.save(Constants.ACCESS_TOKEN, data.token, { domain: config.domainName, expires: moment().add(1,'year').toDate() });
    cookies.remove(Constants.REGISTERED_USER_DATA, { domain: config.domainName});
    return {
        type: Constants.LOGIN_SUCCESS,
        payload: data
    }
}
export function isLoggedIn(data) {
    return {
        type: Constants.LOGIN_SUCCESS,
        payload: data
    }
}
function loginFailure(data, router) {
    cookies.remove(Constants.USER_DATA, { domain: config.domainName })
    cookies.remove(Constants.ACCESS_TOKEN, { domain: config.domainName });
    let errorMsg = ''
    if (data && data.response && data.response.data) {
        errorMsg = data.response.data.message
        if(data.response.data.email){
            setTimeout(()=>{
                router.push({
                    pathname: "/verifyEmail",
                    state: data.response.data
                })
            }
            , 5000)
        }
    } else {
        errorMsg = data.message
    }
    return {
        type: Constants.LOGIN_FAILED,
        payload: errorMsg
    };
}


export function logoutAction(router = '') {
    cookies.remove(Constants.USER_DATA, { domain: config.domainName });
    cookies.remove(Constants.ACCESS_TOKEN, { domain: config.domainName });
    if (router !== '') {
        router.push("/login");
    }
    return {
        type: Constants.LOGOUT_SUCCESS,
        payload: router !== '' ? 'Logged out successfully.' : ''
    }

}

export function countForResendEmailAction(count){
    return {
        type: Constants.RESEND_EMAIL_COUNT_UPDATE,
        payload: count
    }
}

export function resendEmailVerification(data, router) {
    return Constants.apiAction({
        url: API_RESEND_VERIFY_EMAIL,
        method: Constants.REQUEST_POST,
        data: data,
        onSuccess: onResendEmailVerificationSuccess,
        onFailure: onResendEmailVerificationFailure,
        label: Constants.RESEND_EMAIL_VERIFICATION_REQUESTED,
        router: router
    })
}

function onResendEmailVerificationSuccess(data){
    return {
        type: Constants.RESEND_EMAIL_VERIFICATION_SUCCESS,
        payload: data.message
    }
}

function onResendEmailVerificationFailure(data){
    return {
        type: Constants.RESEND_EMAIL_VERIFICATION_FAILED,
        payload: data.message
    }
}

export function registerUser(data, router) {
    return Constants.apiAction({
        url: API_REGISTER,
        method: Constants.REQUEST_POST,
        data: data,
        onSuccess: onRegisterUserSuccess,
        onFailure: onRegisterUserFailure,
        label: Constants.REGISTRATION_REQUESTED,
        router: router
    })
}

function onRegisterUserSuccess(data, router) {
    console.log(data)
    cookies.save(Constants.REGISTERED_USER_DATA, JSON.stringify(data), { domain: config.domainName, expires: moment().add(10,'minutes').toDate() });
    router.push({
        pathname: "/verifyEmail",
        state: data
    });
    return {
        type: Constants.REGISTRATION_SUCCESS,
        payload: data.message
    }
}

function onRegisterUserFailure(data) {
    console.log(data)
    return {
        type: Constants.REGISTRATION_FAILED,
        payload: data.response.data.non_field_errors[0]
    }
}


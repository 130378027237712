import * as Constants from '../constants'

export const apiStart = label => ({
    type: Constants.API_START,
    payload: label
});

export const apiEnd = label => ({
    type: Constants.API_END,
    payload: label
});

export const accessDenied = url => ({
    type: Constants.ACCESS_DENIED,
    payload: {
        url
    }
});

export const apiError = error => ({
    type: Constants.API_ERROR,
    error
});


// Login
export const LOGIN_REQUESTED = "LOGIN_REQUESTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const REGISTRATION_REQUESTED = "REGISTRATION_REQUESTED";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAILED = "REGISTRATION_FAILED";



export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";


export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
export const CHANGE_PASSWORD_REQUESTED = "CHANGE_PASSWORD_REQUESTED";
export const RESEND_EMAIL_VERIFICATION_REQUESTED = "RESEND_EMAIL_VERIFICATION_REQUESTED";
export const RESEND_EMAIL_VERIFICATION_SUCCESS = "RESEND_EMAIL_VERIFICATION_SUCCESS";
export const RESEND_EMAIL_VERIFICATION_FAILED = "RESEND_EMAIL_VERIFICATION_FAILED";
export const RESEND_EMAIL_COUNT_UPDATE = "RESEND_EMAIL_COUNT_UPDATE";

// API RELETED
export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";
export const REQUEST_GET = "GET";
export const REQUEST_DELETE = "DELETE";
export const REQUEST_POST = "POST";
export const REQUEST_PUT = "PUT";


// USER TOKEN
export const ACCESS_TOKEN = "_globalToken";
export const USER_DATA = "USER_DATA"

export const REGISTERED_USER_DATA = "REGISTERED_USER_DATA"

export function apiAction({
    url = "",
    method = REQUEST_GET,
    data = null,
    accessToken = null,
    onSuccess = () => { },
    onFailure = () => { },
    label = "",
    headersOverride = null,
    router = null
}) {
    return {
        type: API,
        payload: {
            url,
            method,
            data,
            accessToken,
            onSuccess,
            onFailure,
            label,
            headersOverride,
            router
        }
    };
}

export const MOMENT_DATE_FORMAT = "DD-MM-YYYY hh:mm a";

export const NO_RESPONSE_ERROR = "Not responded yet.";

import { combineReducers } from 'redux'
import loginInfo from "./login";
import { LOGOUT_SUCCESS } from '../constants';
import apiCalls from "./api";

const appReducer = combineReducers({
    loginInfo,
    apiCalls
})
const rootReducer = (state, action) => {
    // Clear all data in redux store to initial.
    if (action.type === LOGOUT_SUCCESS)
        state = undefined;
    return appReducer(state, action);
};
export default rootReducer;

import axios from "axios";
import * as Constants from '../constants'
import { apiError, apiStart, apiEnd } from "../actions/api";
import config from '../local_config';
const apiMiddleware = ({ dispatch }) => next => action => {

    next(action);

    if (action.type !== Constants.API) return;

    const {
        url,
        method,
        data,
        accessToken,
        onSuccess,
        onFailure,
        label,
        headers,
        router
    } = action.payload

    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
    // axios default configs
    axios.defaults.baseURL = config.apiUrl;
    axios.defaults.headers.common = {};
    axios.defaults.headers.common["Content-Type"] = "application/json";

    if (accessToken !== null)
        axios.defaults.headers.common["Authorization"] = `token ${accessToken}`;

    if (label) {
        dispatch(apiStart(label));
    }

    axios.request({
        url,
        method,
        headers,
        [dataOrParams]: data
    })
        .then(({ data }) => {
            dispatch(onSuccess(data, router));
        })
        .catch((error) => {
            dispatch(apiError(error));
            dispatch(onFailure(error, router));
        })
        .finally(() => {
            if (label) {
                dispatch(apiEnd(label));
            }
        });

}

export default apiMiddleware;
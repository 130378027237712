import React, { Fragment } from 'react'
import "./loadingSpinner.css"


export default function LoadingSpinner() {
    return (
        <Fragment>
            <div className="overlay"></div>
            <div className="spinner"></div>
        </Fragment>
    )
}

const UMS = "UMS";


export const API_LOGIN = "/api/services/auth/";
export const API_REGISTER = "/api/services/auth/register/";
export const API_VERIFY_EMAIL = "/api/services/auth/activate/";
export const API_RESEND_VERIFY_EMAIL = "/api/services/auth/resend-email-verification/";

export const API_LOGOOUT = UMS + "/api/auth/logout";
export const API_CHANGE_PASSWORD = UMS + "/api/auth/change-password";
export const API_REVIEWS = UMS + "/api/reviews/";
export const API_GET_SUPERVISEE_HIERARCHY_REVIEW = UMS + "/api/reviews-loggedin/";
export const API_RESPONSES = UMS + "/api/responses/";
export const API_LAST_REVIEW = UMS + "/api/last-review/";
export const API_GET_SUPERVISEES = UMS + "/api/auth/supervisees/";
export const API_GET_SUPERVISEES_REVIEW = UMS + "/api/auth/supervisees-reviews/";
export const API_GET_SUPPERVISEE_HIERARCHY = UMS + "/api/auth/hierarchy/";
export const API_GET_ALL_REPORTS = UMS + "/api/all-reports/";
export const PM_API_GET_ORG_CHART = UMS + "/api/auth/org-chart/";
export const API_ARCHIVE_REVIEW = UMS + "/api/archived-reviews/";
export const API_GET_ALL_SUPERVISORS = UMS + "/api/auth/supervisors";


import { LOGIN_SUCCESS, LOGIN_FAILED, REGISTRATION_FAILED, LOGOUT_SUCCESS, REGISTRATION_SUCCESS, RESEND_EMAIL_VERIFICATION_SUCCESS, RESEND_EMAIL_VERIFICATION_FAILED, RESEND_EMAIL_COUNT_UPDATE } from "../constants";
import { ToastsStore } from 'react-toasts';

const initialState = {
    isLoggedIn: false,
    userInfo: {},
    error: {},
    passwordUpdated: false,
    countForResendEmail: 60
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                isLoggedIn: true,
                userInfo: action.payload
            }
        case LOGIN_FAILED:
            ToastsStore.error(action.payload);
            return {
                isLoggedIn: false,
                error: action.payload
            }
        case LOGOUT_SUCCESS:
            ToastsStore.success("You have logged out successfully.");
            return initialState
        case REGISTRATION_SUCCESS:
            ToastsStore.success("User registred successfully please check your mail to verify.");
            return {
                ...state,
            }
        case RESEND_EMAIL_VERIFICATION_SUCCESS:
            ToastsStore.success("Successfully resend email for verfication.");
            return {
                ...state,
            }
        case RESEND_EMAIL_VERIFICATION_FAILED:
            ToastsStore.info("Email is already verified.");
            return {
                ...state,
            }
        case RESEND_EMAIL_COUNT_UPDATE:
            return {
                ...state,
                countForResendEmail: action.payload
            }
        case REGISTRATION_FAILED:
            ToastsStore.error(action.payload);
            return {
                ...state
            }
        default:
            return state

    }
}

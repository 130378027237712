import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link,withRouter } from "react-router-dom";
import autoBind from 'react-autobind';
import ubuilder from "../../static/ubuilder.png";
import profileVerified from "../../static/profile_verified_icon.png";
import mobile from "../../static/mobile.svg";
import "./profileVerified.css";
import { registerUser } from "../../actions/login";

class ProfileVerified extends React.Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state={
            count: 5
        }
    }

    componentDidMount() {
        this.startCountdown();
      }
    
    startCountdown = () => {
    const countdownInterval = setInterval(() => {
        this.setState(prevState => ({
        count: prevState.count - 1
        }), () => {
        if (this.state.count === 0) {
            clearInterval(countdownInterval);
            this.props.history.push('/login');
        }
        });
    }, 1000);
    };
    
    render() {
        return (
            <Fragment>
                <div className="container verify-email-body">
                    <div className="row justify-content-center align-items-center height80Vh">
                        <div className="profile-verified">
                            <div className="text-center">
                                <img src={ubuilder} className="ubuilder-icon" alt="ubuilder"/>
                                <br/><br/>
                                <div><img src={profileVerified} className="profile-verified-icon" alt="profileVerified"/></div>
                                <h3>Profile verified!</h3>
                                <p>Your profile is now verified.<br/>You can proceed to <Link to={'/login'}>login</Link></p>
                                <br/>
                                <p>Redirecting in <span className="timer">{this.state.count}s</span></p>
                            </div>
                        </div>
                        <img src={mobile} className="mobile-icon" alt="mobile"/>
                    </div>
                </div>
            </Fragment>
        )
    }
}


export default connect(null, { registerUser })(withRouter(ProfileVerified))
import React, { Fragment } from 'react';
import autoBind from 'react-autobind';
import { loginPost, logoutAction } from '../../actions/login';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import "./login.css"
import { func, bool } from 'prop-types';
import * as Constant from "../../constants";
import cookies from "react-cookies";
import * as QueryString from "query-string"
import config from "../../local_config"
import backgroundForLogin from '../../static/login_background.png'
import ubuilderBlue from '../../static/U-Builder_Logo_Blue_Color.png'
import uniracBlue from '../../static/Unirac_Logo_Blue.png'
import { SOCIAL_MEDIA_LINKS } from '../utils/constants.';
class Login extends React.Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = { username: '', password: '', redirect: '' };

        this.checkForLogout();
        let data = cookies.load(Constant.USER_DATA, { domain:  config.domainName });
        let token = cookies.load(Constant.ACCESS_TOKEN, { domain:  config.domainName})
        if (data && token) {
            this.props.history.push("/");
        }

        const appElement = document.querySelector('.App');
        if(appElement){
            appElement.style.background = `url(${backgroundForLogin})`;
            appElement.style.backgroundRepeat =  'no-repeat';
            appElement.style.height = 'calc(100vh)';
            appElement.style.position = 'relative';
            appElement.style.backgroundSize = 'cover';
            appElement.style.backgroundPosition = '70%';
            appElement.style.margin = '0';
        }
    }

    checkForLogout() {
        const params = QueryString.parse(this.props.location.search);
        console.log(params);
        if (params.action && params.action === 'logout') {
            this.props.logoutAction(this.props.history)
        } else if (params.next) {
            this.props.logoutAction()
        }
    }

    checkForVerificationStatus() {
        const queryParams = new URLSearchParams(window.location.search);
        const paramValue = queryParams.get('isEmailVerified');
        if(paramValue){
            let message="";
            if(paramValue === "invalid-token"){
                message = "Invalid verification token, Please register again";
            }
            else{
                message = "Your email is already verified"
            }
            return (
                <div class="alert alert-danger alert-dismissible fade show">
                    <button type="button" class="close" data-dismiss="alert">&times;</button>
                    {message}
                </div>
            )
        }
    }

    /**
     * Handling input change events.
     * @param  {Object} event 
     */
    handleInputChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value
        });
    }

    /**
     * Form submit function with validation check.
     * @param  {Object} event 
     */
    formSubmit = (event) => {
        event.preventDefault();
        const data = {
            username: this.state.username,
            password: this.state.password
        }
        this.props.loginPost(data,this.props.history);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isLoggedIn !== prevProps.isLoggedIn) {
            const params = QueryString.parse(prevProps.location.search);
            if (params.next) {
                window.location = params.next;
            } else {
                this.props.history.push("/");
            }
        }
    }


    render() {
        return (
            <Fragment>
                <div className="container">
                    <img src={uniracBlue} className="unirac-light-blue-logo" alt="unirac-light-blue-logo"/>
                    <div>
                        <div className="loginBox">
                            <form onSubmit={this.formSubmit} style={{ marginTop: '-10px' }} className="justify-content-center">
                                <div className="text-center login-title">
                                    {this.checkForVerificationStatus()}
                                    <img src={ubuilderBlue} className="ubuilder-blue-logo" alt="ubuider-blue-logo" />
                                </div>
                                <div className="form-group email">
                                    <input type="email" className="form-control" value={this.state.username}
                                        onChange={this.handleInputChange} name="username" required placeholder="Enter Your Email ID" />
                                </div>
                                <div className="form-group password">
                                    <input type="password" className="form-control" value={this.state.password}
                                        onChange={this.handleInputChange} name="password" required placeholder="Enter Your Password" />
                                </div>
                                <div className="form-group forgot-password-part">
                                    <span><input type="checkbox"/> Keep Me Logged In</span>
                                    <span><a href={config.forgotPasswordLink}>Forget password ?</a></span>
                                </div>
                                <button type="submit" className="btn btn-block submitButtonColor">
                                    Login
                                    <img src={require('../../static/forward_arrow_icon.svg')} className="forward-icon" alt="forward-icon"/>
                                </button>
                            </form>
                            <hr/>
                            <div className="text-center loginRegNavLink">
                                Don't have an account yet ? <Link to={'/registration'} className="register-link">Sign Up</Link>
                            </div>
                            <div className="text-center social-media-links">
                                <a href={SOCIAL_MEDIA_LINKS.facebook}><img src={require('../../static/Icon awesome-facebook.svg')} alt="facebook-icon"/></a>
                                <a href={SOCIAL_MEDIA_LINKS.twitter}><img src={require('../../static/Icon awesome-twitter.svg')} alt="twitter-icon"/></a>
                                <a href={SOCIAL_MEDIA_LINKS.linkedin}><img src={require('../../static/Icon awesome-linkedin.svg')} alt="linkedin-icon"/></a>
                                <a href={SOCIAL_MEDIA_LINKS.instagram}><img src={require('../../static/Icon awesome-instagram.svg')} alt="instagram-icon"/></a>
                                <a href={SOCIAL_MEDIA_LINKS.instagram}><img src={require('../../static/Icon awesome-youtube.svg')} alt="youtube-icon"/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        isLoggedIn: state.loginInfo.isLoggedIn
    }
}

Login.propTypes = {
    loginPost: func,
    isLoggedIn: bool
}

export default connect(mapStateToProps, { loginPost, logoutAction })(withRouter(Login))
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Store from "./store";
import { Provider } from 'react-redux';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';


const storeInstance = Store()

ReactDOM.render(
  // <React.StrictMode>
    <Provider store={storeInstance}>
      <App></App>
    </Provider>
,
  // </React.StrictMode>,
  document.getElementById('root')
);


import React, { Component } from 'react';
import "./navbar.css"
import { connect } from "react-redux";
import { logoutAction } from "../../actions/login";
import { withRouter } from "react-router-dom";
import autoBind from 'react-autobind';
import { func, bool } from 'prop-types';
import unirac from '../../static/Unirac_black.png';
import avtar from "../../static/Icon open-person.svg";
import dropdown from "../../static/Icon material-arrow-drop-down.svg";

class NavBar extends Component {


    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            showDropdown: false
        };

    }

    logout() {
        this.props.logoutAction(this.props.history);
    }

    renderUserMenu = (logout, userInfo) => {
        const username = userInfo.username;
        return (
            <ul className="navbar-nav ml-auto" 
            onMouseEnter={()=>this.setState({showDropdown: true})}
            onMouseLeave={()=>this.setState({showDropdown: false})}
            >
                <li className="nav-item dropdown align-self-center">
                    <div id="navbarDropdownMenuLink" data-toggle="dropdown" aria-expanded="false" className="user-profile" >
                        {username}
                        <img height="40" width="40"
                            className="nav-link avtar"
                            src={avtar} alt="" >
                        </img>
                        <img height="30" width="30"
                            className="nav-link dropdown"
                            src={dropdown} alt="" >
                        </img>
                        
                    </div>
                    <div className={`dropdown-menu dropdown-menu-right ${this.state.showDropdown ? `show` : ``}`} aria-labelledby="navbarDropdownMenuLink">
                        <span className="dropdown-item cursorPointer" onClick={() => { logout() }} >Log out</span>

                    </div>
                </li>
            </ul>
        )
    }

    render() {

        const isLoggedIn = this.props.isLoggedIn;
        const shouldShowNavbar = !(window.location.href.includes("/login") || window.location.href.includes("/registration"));
        return (
            shouldShowNavbar ?
            <React.Fragment>
                <nav className="navbar navbar-expand-sm navbar-light bg-light border-bottom sso-navbar"  >
                    <a className="navbar-brand navbar-brand" href="/">
                        <img
                            src={unirac} className="d-inline-block align-top unirac-logo" alt="" />
                    </a>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        {isLoggedIn && this.renderUserMenu(this.logout, this.props.userInfo)}
                    </div>
                </nav>
            </React.Fragment> : null
        )
    }
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.loginInfo.isLoggedIn,
        userInfo: state.loginInfo.userInfo
    }
}
export default connect(mapStateToProps, { logoutAction })(withRouter(NavBar));


NavBar.propTypes = {
    logoutAction: func,
    isLoggedIn: bool
}
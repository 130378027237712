
import { API_START, API_END, API_ERROR } from "../constants";

const initialState = {
    isLoading: false,
    error: {}
}

export default (state = initialState, action) => {

    switch (action.type) {
        case API_START:
            return {
                ...state,
                isLoading: true
            };
        case API_ERROR:
            return {
                error: action.error,
                isLoading: false
            };
        case API_END:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state

    }
}